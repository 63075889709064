import React from 'react';
import SEO from '../components/Seo';

const style = {
  display: 'flex',
  margin: '20px 5vw',
  padding: '20px 5vw',
  backgroundColor: 'white',
  justifyContent: 'center',
  alignItems: 'center',
};

const NotFoundPage = () => {
  return (
    <div style={style}>
      <SEO title="Page Not found" />
      <h1>PAGE NOT FOUND</h1>
    </div>
  );
};

export default NotFoundPage;
